import React, { useEffect, useContext, useState } from "react";
import swal from "sweetalert";
import { Container } from "react-bootstrap";

import LoadingContext from "../../store/loading-context";
import PanCardService from "../../services/PanCardService";
import HttpError from "../../utill/HttpError";

import $ from "jquery";
window.$ = $;

const UatNsdlEkycRedirect = () => {
  const [url, setUrl] = useState(
    "https://preprod.assisted-service.egov-nsdl.com/SpringBootFormHandling/newPanReq"
  );

  const [data, setData] = useState();
  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
    document.title = "e-KYC NSDL";
    fetch();
  }, []);

  const fetch = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const authorization = params.get("authorization");
    const type = params.get("type");

    if (!authorization) {
      window.location.reload();
      return;
    }

    if (type && type == "CR") {
      setUrl(
        "https://preprod.assisted-service.egov-nsdl.com/SpringBootFormHandling/crPanReq"
      );
    }

    loadingCtx.startLoading();

    try {
      let responseData;

      if (type == "CR") {
        responseData = await PanCardService.uatCrApplyPan(authorization);
      } else {
        responseData = await PanCardService.uatApplyPan(authorization);
      }

      const status = responseData.status;
      const message = responseData.message;

      if (status == "SUCCESS") {
        console.log(JSON.stringify(responseData.data.req));
        // return;
        setData(JSON.stringify(responseData.data.req));
        $("#panform").submit();
      } else {
        // window.location.href = responseData.data.redirect_url;
        swal("Opps!", responseData.message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      swal("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };
  return (
    <Container fluid>
      <center style={{ paddingTop: "40px" }}>
        <p>Redirecting to PAN Application Page</p>
        <p>Request wait ...</p>
      </center>
      <form method="POST" action={url} id="panform">
        <input type="hidden" name="req" value={data} />
      </form>
    </Container>
  );
};

export default UatNsdlEkycRedirect;
