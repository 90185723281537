import React, { useContext } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Route } from "react-router-dom";

import LoadingContext from "./store/loading-context";
import Loading from "./components/UI/Loading/Loading";

import SsoNsdlEkycRedirect from "./components/NsdlEkycRedirect/SsoNsdlEkycRedirect";
import NsdlEkycRedirect from "./components/NsdlEkycRedirect/NsdlEkycRedirect";
import UatNsdlEkycRedirect from "./components/NsdlEkycRedirect/UatNsdlEkycRedirect";

function App() {
  const loadingCtx = useContext(LoadingContext);

  return (
    <div className="App">
      {loadingCtx.isLoading && <Loading />}

      <BrowserRouter>
        <Switch>
          <Route
            path="/sso_nsdl_ekyc_redirect"
            component={SsoNsdlEkycRedirect}
          />
          <Route path="/nsdl_ekyc_redirect" component={NsdlEkycRedirect} />
          <Route
            path="/uat_nsdl_ekyc_redirect"
            component={UatNsdlEkycRedirect}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
