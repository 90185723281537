import axios from "axios";

class PanCardService {
  fetchCommercial = async (token, quantity) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}pancard/fetch_commercial`;

    const requestBody = {
      quantity: quantity,
    };

    const response = await axios.post(requestUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  buyCoupon = async (token, userId, pin, quantity) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}pancard/buy_coupon`;

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const requestBody = {
      quantity: quantity,
    };

    const response = await axios.post(requestUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  getAuthorization = async (
    token,
    userId,
    pin,
    application_type,
    category,
    name,
    dob,
    gender,
    mobile,
    email,
    is_physical_pan_required
  ) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}nsdl_pancard/get_authorization`;

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const requestBody = {
      application_type,
      category,
      name,
      dob,
      gender,
      mobile,
      email,
      is_physical_pan_required,
    };

    const response = await axios.post(requestUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  applyPan = async (token) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}nsdl_pancard/apply?token=`;

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  crApplyPan = async (token) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}nsdl_pancard/cr_apply?token=`;

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  incomplete = async (token) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}nsdl_pancard/incomplete?token=`;

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  uatCrApplyPan = async (token) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}nsdl_pancard/uat_cr_apply?token=`;

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  uatApplyPan = async (token) => {
    const requestUrl =
      "http://192.168.0.108:5001/restapi/nsdl_pancard/uat_apply?token=";

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  applyPanCardSso = async (token) => {
    const requestUrl = `https://api.formaxpay.com/api/nsdl/apply_pan_card?token=`;

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  crApplyPanCardSso = async (token) => {
    const requestUrl = `https://api.formaxpay.com/api/nsdl/cr_apply_pan_card?token=`;

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  incompleteSso = async (token) => {
    const requestUrl = `https://api.formaxpay.com/api/nsdl/incomplete?token=`;

    const requestBody = null;

    const response = await axios.post(requestUrl + token, requestBody, {
      headers: null,
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };

  getSubscription = async (token, userId, pin) => {
    const requestUrl = `${process.env.REACT_APP_SERVER_URL}nsdl_pancard/get_subscription`;

    const requestBody = null;

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const response = await axios.post(requestUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
    });

    if (!response.status || !response.data) {
      throw new Error("Request Failed!");
    }
    return response.data;
  };
}

export default new PanCardService();
